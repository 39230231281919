import React, { useState } from "react";
import { Button, Center, IconButton } from "@chakra-ui/react";
import { Page } from "./Page";
import { ErrorPage } from "./ErrorPage";
import { episodes } from "./episodes";
import { DownloadIcon } from "@chakra-ui/icons";
import { downloadFile } from "./helpers/fileHelpers";

export function App() {
  const urlParts = document.location.pathname.split("/");
  const episodeIdFromUrl = parseInt(urlParts[urlParts.length - 1]);

  const [episodeId, setEpisodeId] = useState(episodeIdFromUrl);

  const episode = episodes.find((e) => e.id === episodeId);

  if (!episode) {
    return <ErrorPage />;
  }

  const audio = require("./assets/" + episodeId + ".mp3");

  return (
    <Page subtitle={episode.title}>
      <>
        <div
          style={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
        >
          <audio src={audio.toString()} controls />
          <DownloadIcon
            onClick={() =>
              downloadFile(
                audio.toString(),
                episode.id + " - " + episode.title + ".mp3"
              )
            }
          />
        </div>

        <Center height="20px" />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            disabled={episodeId === 1}
            onClick={() => setEpisodeId(episodeId - 1)}
          >
            Forrige
          </Button>
          <Button
            disabled={episodeId === 9}
            onClick={() => setEpisodeId(episodeId + 1)}
          >
            Neste
          </Button>
        </div>
      </>
    </Page>
  );
}
