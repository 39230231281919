import React, { ReactNode } from "react";
import Logo from "./assets/logo.jpg";
import {
  Box,
  Center,
  ChakraProvider,
  Divider,
  Heading,
} from "@chakra-ui/react";

interface Props {
  subtitle?: string;
  children: ReactNode;
}

export function Page({ children, subtitle = "" }: Props) {
  return (
    <ChakraProvider>
      <Center>
        <Box maxW="32rem" padding={"1rem"}>
          <div
            style={{ display: "flex", columnGap: "1rem", alignItems: "center" }}
          >
            <img src={Logo} style={{ height: "64px" }} />
            <div>
              <Heading size={"xl"}>Guds lam</Heading>
              {subtitle && <Heading size={"md"}>{subtitle}</Heading>}
            </div>
          </div>

          <Center height="40px">
            <Divider orientation={"horizontal"} />
          </Center>
          {children}
        </Box>
      </Center>
    </ChakraProvider>
  );
}
