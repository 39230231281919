export const episodes = [
    { id: 1, title: "Palmesøndag"},
    { id: 2, title: "Mandag"},
    { id: 3, title: "Tirsdag"},
    { id: 4, title: "Onsdag"},
    { id: 5, title: "Skjærtorsdag"},
    { id: 6, title: "Langfredag"},
    { id: 7, title: "Påskeaften"},
    { id: 8, title: "1. påskedag"},
    { id: 9, title: "2. påskedag"},
]