import React from "react";
import { Page } from "./Page";
import { episodes } from "./episodes";
import { Button, Center, Text } from "@chakra-ui/react";
import { config } from "./config";

export function ErrorPage() {
  return (
    <Page subtitle={"Kapitteloversikt"}>
      <Center style={{ display: "flex", flexFlow: "column", rowGap: "10px" }}>
        <Text marginY={"10px"}>Hvilket kapittel vil du høre?</Text>
        {episodes.map((episode) => (
          <Button
            width={"100%"}
            onClick={() => (document.location = config.baseUrl + episode.id)}
          >
            {episode.title}
          </Button>
        ))}
      </Center>
    </Page>
  );
}
