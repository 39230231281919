export function downloadFile(filePath: string, filename: string): void {
  const link = document.createElement("a");
  link.href = filePath;
  link.download = filename;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function downloadTextFile(
  content: string,
  mimeType: string,
  filename: string
): void {
  downloadFile(
    `data:${mimeType};base64,${Buffer.from(content).toString("base64")}`,
    filename
  );
}
